import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';
import ContentSection from '@frontend/ui-kit/Components/ContentSection';
import Heading, {HEADING_TYPES} from '@frontend/ui-kit/Components/Heading';
import Text from '@frontend/ui-kit/Components/Text';
import Row from '@frontend/ui-kit/Components/Row';
import Column from '@frontend/ui-kit/Components/Column';
import Input from '@frontend/ui-kit/Components/Input';
import Select from '@frontend/ui-kit/Components/Select';
import Button, {BUTTON_TYPES} from '@frontend/ui-kit/Components/Button';
import PopupContent from '@frontend/ui-kit/Components/PopupContent';
import Tooltip from '@frontend/ui-kit/Components/Tooltip';
import MarkupEditorField from '@frontend/ui-kit/Components/MarkupEditorField';
import Icon, {ICON_TYPES} from '@frontend/ui-kit/Components/Icon';
import {Field} from '../../shared/FormComponents';
import TemplatePicker from '../../shared/TemplatePicker';
import withPopup from '../../../HOC/withPopup';
import {setSelectedCustomerProfile, setActiveDecisionTab, setActiveDecisionKind, setCustomerAccessToken} from '../../../actions/decisionCenter';
import {setActiveNpi} from '../../../actions/providers';
import {
    getCardTemplates,
    getSelectedCustomerProfile,
    getCustomerRelatedProfiles,
    getSteerageTypes,
    getCustomSteerageTypes,
    getDecisionAutofillTemplate,
    getWalletCards,
    getKisxData
} from '../../../selectors/decisionCenter';
import useForm from '../../../hooks/useForm';
import {getEqual, equal, isEmpty, isFinite, negateFunc, getItemKeyValue, generateUniqueId, normalizeMarkupEditor} from '../../../utils';
import {DECISION_TYPES, CARD_TEMPLATE_CATEGORIES, DECISION_KINDS} from '../../../constants';
import {AUTOFILL_TEMPLATE_OPTIONS} from '../../../options';
import styles from './index.module.scss';

const POPUP_ID = 'generalInfoPopup';
const TOOLTIP_CONTENT = 'You can\'t add this decision type for not invited customer profile!';
const KISX_GREETING_TITLES = {
    'KISx - Imaging form template': 'KISxCard - IMAGING',
    'KISx - Surgery form template': 'KISxCard - SURGERY'
};

const generateBaseWalletCardDecision = ({order}) => ({
    cid: generateUniqueId(),
    kind: DECISION_KINDS.walletCard,
    is_recommended: false,
    order,
    details: {
        category: null,
        plan_id: null
    }
});

const getCustomerRelatedProfileOption = ({full_name: fullName, id, main_user_id: mainUserId}) => (
    {value: id, label: `${fullName} ${!mainUserId ? ' (not invited)' : ''}`}
);

const getSteerageTypeOption = ({id, name}) => ({label: name, value: id});

const GeneralInfo = ({decisionType, className, openPopup, closePopup}) => {
    const dispatch = useDispatch();
    const form = useForm();
    const cardTemplates = useSelector(getCardTemplates);
    const steerageTypes = useSelector(getSteerageTypes);
    const customSteerageTypes = useSelector(getCustomSteerageTypes);
    const {main_user_id: mainCustomerId} = useSelector(getSelectedCustomerProfile);
    const customerRelatedProfiles = useSelector(getCustomerRelatedProfiles);
    const customerRelatedProfilesOptions = useMemo(() => customerRelatedProfiles.map(getCustomerRelatedProfileOption), [customerRelatedProfiles]);
    const steerageTypesOptions = useMemo(() => steerageTypes.map(getSteerageTypeOption), [steerageTypes]);
    const customSteerageTypesOptions = useMemo(() => customSteerageTypes.map(getSteerageTypeOption), [customSteerageTypes]);
    const isProviderDecisionType = [DECISION_TYPES.provider, DECISION_TYPES.genericProvider].some(getEqual(decisionType));
    const isReasonForVisitAvailable = equal(decisionType, DECISION_TYPES.appointment);
    const decisionAutofillTemplate = useSelector(getDecisionAutofillTemplate);
    const kisxData = useSelector(getKisxData);
    const walletCards = useSelector(getWalletCards);
    const {values} = form.getState();
    const {requested_for: customerId, decisions} = values;

    const onChangeSteerageType = data => value => {
        const {monetary_value: monetaryValue} = data.find(getEqual(value, 'id'));

        form.change('monetary_value', monetaryValue);
    };

    const openChangingCustomerPopup = value => {
        const onOpenPopup = () => {
            const filteredDecisions = decisions.filter(negateFunc(getEqual(DECISION_KINDS.walletCard, 'kind')));
            const customerProfile = customerRelatedProfiles.find(getEqual(value, 'id'));

            form.batch(() => {
                form.change('decisions', filteredDecisions);
                form.change('requested_for', value);
            });

            if (!isEmpty(filteredDecisions)) {
                const [firstDecision] = filteredDecisions;
                const {kind, details} = firstDecision;
                const {npi} = details;

                dispatch(setActiveDecisionKind(kind));
                dispatch(setActiveNpi(npi));
            }

            dispatch(setSelectedCustomerProfile(customerProfile));
            dispatch(setCustomerAccessToken(null));
            dispatch(setActiveDecisionTab(0));
            closePopup();
        };

        const actionBar = (
            <React.Fragment>
                <Button type={BUTTON_TYPES.primary} onClick={onOpenPopup}>Yes</Button>
                <Button type={BUTTON_TYPES.secondary} onClick={closePopup}>No</Button>
            </React.Fragment>
        );

        const popupContent = <Text isInline>Changing the profile may change the available benefits, wallet cards you recommended will be dropped when profile is changed. Do you want to continue?</Text>;
        const popupProps = {title: 'Change profile', actionBar, children: popupContent};
        const children = <PopupContent {...popupProps}/>;

        openPopup({modalType: 'simple', children});
    };

    const onChangeCustomerProfile = async value => {
        if (equal(value, customerId)) {
            return;
        }

        const areWalletCardDecisionsExisted = decisions.some(getEqual(DECISION_KINDS.walletCard, 'kind'));

        if (areWalletCardDecisionsExisted && isProviderDecisionType) {
            openChangingCustomerPopup(value);

            return;
        }

        const customerProfile = customerRelatedProfiles.find(getEqual(value, 'id'));

        form.change('requested_for', value);
        dispatch(setSelectedCustomerProfile(customerProfile));
    };

    const onAddWalletCardDecision = () => {
        const largestOrder = Math.max(...decisions.map(getItemKeyValue('order')));
        const decisionOrder = isFinite(largestOrder) ? largestOrder + 1 : 0;
        const baseWalletCardDecision = generateBaseWalletCardDecision({order: decisionOrder});
        const updatedDecisions = [...decisions, baseWalletCardDecision];

        form.change('decisions', updatedDecisions);

        dispatch(setActiveNpi(null));
        dispatch(setActiveDecisionTab(updatedDecisions.length - 1));
        dispatch(setActiveDecisionKind(DECISION_KINDS.walletCard));
    };

    const getGreetingsItem = title => cardTemplates[CARD_TEMPLATE_CATEGORIES.greetings].find(item => equal(item.title, title));

    const getPlanIdForKISx = (title, walletCards) => {
        const item = walletCards.find(item => equal(item.title, title));

        return item.planId;
    };

    const onChangeAutofillTemplate = value => {
        const kisxItem = kisxData.find(item => equal(item.title, value));
        const activeDecisionTab = decisions.length;

        if (!kisxItem) {
            return;
        }

        const greetingTitle = KISX_GREETING_TITLES[value];
        const greetingsItem = getGreetingsItem(greetingTitle);
        const planIdForKISx = getPlanIdForKISx('KisX Card', walletCards);

        if (!greetingsItem) {
            return;
        }

        const {content: kisxContent} = kisxItem;
        const {content: greetingsContent} = greetingsItem;
        const {steerage_type_id: steerageTypeId} = kisxContent;

        form.change('steerage_type_id', steerageTypeId);
        form.change('resolution.reason', greetingsContent);

        onAddWalletCardDecision();

        form.batch(() => {
            form.change(`decisions[${activeDecisionTab}].is_recommended`, true);
            form.change(`decisions[${activeDecisionTab}].details.is_pre_cert_event`, true);
            form.change(`decisions[${activeDecisionTab}].details.category`, 'other');
            form.change(`decisions[${activeDecisionTab}].details.pre_cert_decision`, 'HCC');
            form.change(`decisions[${activeDecisionTab}].details.plan_id`, planIdForKISx);
            form.change(`decisions[${activeDecisionTab}].details.walletCardId`, planIdForKISx);
        });
    };

    return (
        <ContentSection className={classnames(className)}>
            <Heading className='mb-15' type={HEADING_TYPES['4']}>
                Heading
            </Heading>

            <Row>
                <Column sm={6} className='mb-12'>
                    <Select value={customerId}
                        options={customerRelatedProfilesOptions}
                        onChange={onChangeCustomerProfile}
                        label='Select Profile this service is for'/>
                </Column>
                <Column sm={6} className='mb-12'>
                    <Field name='resolution.title'>
                        {props => (
                            <Input {...props}
                                label='Card title *(What member will see as an Inbox card title)'
                                maxLength={255}/>
                        )}
                    </Field>
                </Column>
            </Row>
            <Row>
                <Column sm={6}>
                    <Field name='resolution.reason' parse={normalizeMarkupEditor} className='mb-12'>
                        {props => <MarkupEditorField {...props} label='Greetings'/>}
                    </Field>
                    <TemplatePicker field='resolution.reason'
                        templates={cardTemplates[CARD_TEMPLATE_CATEGORIES.greetings]}
                        isMarkup
                        placeholder='Select a "Greetings" template'/>
                </Column>

                {isReasonForVisitAvailable && (
                    <Column sm={6}>
                        <Field name='resolution.reason_for_visit'>
                            {props => <Input {...props} label='Reason for visit'/>}
                        </Field>
                    </Column>
                )}

                {isProviderDecisionType && (
                    <Column sm={6}>
                        <Field name='steerage_type_id' onChange={onChangeSteerageType(steerageTypes)} className='mb-12'>
                            {props => <Select {...props} label='Steerage type' options={steerageTypesOptions}/>}
                        </Field>

                        <Field name='custom_steerage_type_id' onChange={onChangeSteerageType(customSteerageTypes)}>
                            {props => <Select {...props} label='Custom Steerage type' options={customSteerageTypesOptions}/>}
                        </Field>
                    </Column>
                )}
            </Row>
            <Row>
                <Column sm={6}/>
                {isProviderDecisionType && (
                    <Column sm={6} className='mb-12'>
                        <Select value={decisionAutofillTemplate}
                            options={AUTOFILL_TEMPLATE_OPTIONS}
                            onChange={onChangeAutofillTemplate}
                            label='Decision Autofill Template'
                            data-testid='template-selector'/>
                    </Column>
                )}
            </Row>

            {isProviderDecisionType && (
                <Row middle='xs' end='sm' className='pt-15'>
                    <Column>
                        <Button type={BUTTON_TYPES.secondary}
                            disabled={!mainCustomerId}
                            onClick={onAddWalletCardDecision}>
                            Add a Wallet Card decision
                        </Button>

                    </Column>
                    {!mainCustomerId && (
                        <Column>
                            <Tooltip className={styles.generalInfoTooltip} content={TOOLTIP_CONTENT}>
                                <Icon type={ICON_TYPES.info}/>
                            </Tooltip>
                        </Column>
                    )}
                </Row>
            )}
        </ContentSection>
    );
};

GeneralInfo.propTypes = {
    decisionType: PropTypes.string,
    className: PropTypes.string,
    openPopup: PropTypes.func,
    closePopup: PropTypes.func
};

GeneralInfo.defaultProps = {
    className: ''
};

export {GeneralInfo as TestableGeneralInfo};
export default withPopup(POPUP_ID)(GeneralInfo);
